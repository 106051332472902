<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem, provideUseId } from '@headlessui/vue'

provideUseId(() => useId())
const toLocalePath = useLocalePath()
const hover = ref(false)

const props = defineProps({
  user: {
    type: Object as PropType<ReturnType<typeof useAuth>['data']['value']>,
    default: null,
    required: false
  }
})

function handleLogout(close?: () => void) {
  if (close) close()
  if (isJobBoard()) {
    navigateTo(toLocalePath('/auth/logout'))
  } else {
    navigateTo(`${useRuntimeConfig().public.tlUrl}/logout`, {
      external: true
    })
  }
}

function handleLink(path: string, query: Record<string, string>, close?: () => void) {
  if (close) close()
  navigateTo(
    toLocalePath({
      name: path,
      query
    })
  )
}
</script>

<template>
  <Menu
    v-slot="{ open }"
    as="div"
    class="relative inline-block items-center text-left"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div>
      <MenuButton
        aria-label="avatar"
        alt="avatar"
        class="flex h-[2.375rem] w-full items-center justify-center gap-[0.31rem] rounded-button bg-opacity-20 px-2 text-t5 font-light text-primary"
      >
        <ProfileUserAvatar :user="user" />
        <div
          v-if="$viewport.isLessThan('lg')"
          class="h-[1.25rem] w-[1.25rem] transform transition-all"
          :class="{ 'rotate-180': open }"
        >
          <IconsFigmaExpandMoreOutlined />
        </div>
        <div
          v-else
          class="h-[1.25rem] w-[1.25rem] transform transition-all"
          :class="{ 'rotate-180': hover }"
        >
          <IconsFigmaExpandMoreOutlined />
        </div>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        v-if="hover"
        :static="!$viewport.isLessThan('md')"
        class="absolute right-0 z-40 mt-2 w-fit min-w-[16rem] origin-top-right rounded-menu bg-white px-[1.25rem] py-[1.25rem] text-t5 font-light shadow-menu ring-1 ring-primary ring-opacity-5 focus:outline-none lg:mt-0"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <div v-if="hover" class="flex flex-row items-center gap-[0.62rem]">
          <!-- <ProfileUserAvatar :user="props.user" /> -->
          <div v-if="props.user" class="text-t4 font-medium">
            {{ props.user.first_name }} {{ props.user.last_name }}
          </div>
        </div>
        <div class="mb-[2.5rem] mt-[1.25rem] flex flex-col gap-[1.25rem]">
          <MenuItem v-if="isPrimaryCandidate(user)" v-slot="{ active, close }">
            <a
              tabindex="0"
              href="/profile"
              :class="{ 'underline ': active }"
              @click.prevent.stop="handleLink('profile', { tab: 'profile' }, close)"
            >
              {{ $t('jobBoard.navBar.myProfile') }}
            </a>
          </MenuItem>
          <MenuItem v-if="isPrimaryCandidate(user)" v-slot="{ active, close }">
            <a
              tabindex="0"
              href="/profile"
              :class="{ 'underline ': active }"
              @click.prevent.stop="handleLink('profile', { tab: 'savedJobs' }, close)"
            >
              {{ $t('jobBoard.navBar.mySavedJobs') }}
            </a>
          </MenuItem>
          <MenuItem v-if="isPrimaryCandidate(user)" v-slot="{ active, close }">
            <a
              tabindex="0"
              href="/profile"
              :class="{ 'underline ': active }"
              @click.prevent.stop="handleLink('profile', { tab: 'appliedJobs' }, close)"
            >
              {{ $t('jobBoard.navBar.myApplications') }}
            </a>
          </MenuItem>
          <MenuItem v-slot="{ active, close }">
            <a
              tabindex="0"
              href="/profile"
              :class="{ 'underline ': active }"
              @click.prevent.stop="handleLink('profile', { tab: 'setting' }, close)"
            >
              {{ $t('jobBoard.navBar.accountSetting') }}
            </a>
          </MenuItem>
        </div>
        <div class="border-t-[1px] border-gray-30"></div>
        <div class="mt-[1.25rem] flex flex-col gap-[1.25rem] text-t6">
          <MenuItem v-slot="{ active, close }">
            <a
              tabindex="0"
              as="button"
              :class="{ 'underline ': active }"
              :custom="true"
              href="/"
              @click.prevent.stop="() => handleLogout(close)"
            >
              {{ $t('common.auth.logout') }}
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
